@import "../../scss/partials/colors.scss";

.scan {
  .scan-holder {
    img {
      margin: 0 auto;
      display: flex;
    }
  }
}
