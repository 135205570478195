$main-font: Sans-Serif;

body,
header,
section,
nav,
div,
p,
h1,
h2,
h3,
h4,
h5,
h6,
input,
textarea,
select,
button,
li {
    font-family: $main-font;
}
