@import "../../scss/partials/colors.scss";

.home {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 120px;
    height: 120px;
    margin: -60px 0 0 -60px;
    transform: rotateY(45deg);

    -moz-animation: rotateAnimation 2.5s linear infinite;
    -webkit-animation: rotateAnimation 2.5s linear infinite;
    animation: rotateAnimation 2.5s linear infinite;
    cursor: pointer;
  }

  .pi-replay {
    padding-top: 15rem;
    color: $text-color;
  }
}

@-moz-keyframes rotateAnimation {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}
@-webkit-keyframes rotateAnimation {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}
@keyframes rotateAnimation {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}
