@import "./colors.scss";

body {
  background-color: $violet;
}

a {
  text-decoration: none;
  color: $text-color;
}

ul {
  list-style: none;
}

button,
a.btn {
  background-color: $text-color;
  outline: 0;
  border: 0;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  font-weight: bold;
  color: $violet-dark;
  font-size: 1rem;
  display: flex;
  &:active {
    opacity: 0.7;
    background-color: $violet-light;
  }

  i {
    color: $violet-dark;
  }
  &.disabled {
    opacity: 0.5;
    cursor: none;
  }
}

.text-gold {
  color: $text-color;
}

.text-violet-dark {
  color: $violet-dark;
}

.p-sidebar {
  background-color: $panel-color;

  .p-sidebar-icon {
    color: $text-color;
    margin-top: 2rem;
    margin-right: 2rem;
    background-color: $violet-dark;
    border-radius: 50%;
    padding: 0.3rem;
  }

  li {
    margin-right: 2rem;
    margin-top: 0.5rem;
    a {
      color: $violet-dark !important;
      font-size: 1.5rem;
      text-transform: uppercase;
      padding: 0 1rem;

      &:hover {
        border-radius: 20px;
      }
      &:active {
        background-color: $violet-light;
      }
    }
  }
}

ul {
  li {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    cursor: pointer;

    p {
      margin: 0.5rem;
    }

    i {
      background-color: $violet-dark;
      border-radius: 50%;
      padding: 0.3rem;
      color: $text-color;
      margin-right: 0.5rem;
    }

    &:hover {
      border-radius: 20px;
    }
    &:active {
      background-color: $violet-light;
    }
  }
}

.panel {
  background-color: $panel-color;
  color: black;
}

.top-bar {
  width: 100%;
}

.click {
  cursor: pointer;
}

*.unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
    Introduced in Internet Explorer 10.
    See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
  */
  -ms-user-select: none;
  user-select: none;
}
