@import "../../scss/partials/colors.scss";

.search {
  ul {
    padding-left: 0.5rem;
    padding-top: 1rem;
    li {
      padding: 0.3rem 0.5rem;
    }
  }

  .p-listbox-list-wrapper {
    background-color: $panel-color;
  }

  .p-listbox-header {
    margin-bottom: 1.5rem;
  }

  .p-listbox-filter-icon {
    right: 1rem;
  }

  .title {
    background-color: $violet-dark;
    color: $text-color;
    font-weight: bold;
  }

  .result {
    min-width: 5rem;
  }

  .info {
    min-width: 10rem;
    height: 100%;
    overflow-y: scroll;
    height: 415px;
  }

  .p-highlight {
    background-color: $violet-light;
    border-radius: 20px;
  }

  .drop {
    cursor: pointer;
  }

  .description {
    line-height: 1.5rem;
  }
  .highlight {
    background-color: $violet-light;
  }
}
