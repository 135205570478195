@import "./colors.scss";

@media (max-width: 600px) {
  .top-bar {
    background-color: $violet;
    z-index: 99;
  }
}

@media (min-width: 601px) {
  .panels {
    div {
      &:nth-child(1) {
        order: 0;
      }
      &:nth-child(2) {
        order: 3;
      }
      &:nth-child(3) {
        order: 5;
      }
      &:nth-child(4) {
        order: 1;
      }
      &:nth-child(5) {
        order: 4;
      }
      &:nth-child(6) {
        order: 5;
      }
      &:nth-child(7) {
        order: 6;
      }
      &:nth-child(8) {
        order: 7;
      }
    }
  }
}

@media (hover: hover) {
  ul {
    li:hover {
      background-color: $violet-light;
    }
  }

  .p-sidebar {
    li {
      &:hover {
        background-color: $violet-light;
      }
    }
  }

  button,
  a.btn {
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
}
