@import "../../scss/partials/colors.scss";

.matrixdrop {
  .title {
    background-color: $violet-dark;
    color: $text-color;
    font-weight: bold;
  }

  .content {
    min-height: 2rem;
    line-height: 1.5;
    padding: 1rem;
  }

  .header {
    background-color: $panel-color;
    color: black;
    font-weight: bold;
  }
}
