@import '../../scss/partials/colors.scss';

.box
{
    background-color: $violet-dark;
    padding:20px 50px;
    
    
    margin:5px;

    color: $text-color;
    text-align: center;
    letter-spacing: 2px;

    &:hover{
        opacity: 0.8;
    }

    img
    {
        width: 82px;
        cursor: pointer;
        
    }
}