@import "../../scss/partials/colors.scss";

.result {
  .panel {
    .title {
      color: $violet-dark;
      font-weight: bold;
      padding: 0rem 1rem;
    }

    .list {
      .row {
        display: flex;
        div {
          &:first-child {
            font-weight: bold;
          }
        }
        .col-2 {
          text-align: right;
          width: 5rem;
        }
      }
    }
  }

  .panels {
    div {
      flex: auto;
    }
  }
}
